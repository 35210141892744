import React from "react";

export const unitChoices = [
  "Number",
  "GBP (£)",
  "AUD ($)",
  "Percentage (%)",
  "tCO2e",
  "tCO2e per annum",
  "tonnes",
  "kWh",
  "Credits",
  "Days",
  "Weeks",
  "Litres",
  "Miles",
  "M3",
  "Units",
  "kg",
  "Hours",
  "kWh/m2/yr",
  "Degrees Centigrade (⁰C)",
  "kgCO2e/kWh",
  "M2",
  "km",
  "kL",
  "£/kWh",
  "£/L",
  "$/kWh",
  "$/L",
  "tCO2e (3)",
];

export const frequencyUnits = ["Days", "Weeks", "Months", "Quarters", "Years"];

export const repFreqChoices = [null, "ONCE", "MONTHS", "DAYS", "WEEKS", "QUARTERS", "YEARS"];

export const metricFields = {
  name: { value: "", class: "col-sm-8" },
  unit: {
    value: unitChoices[0],
    class: "col-sm-4",
    inputType: "select",
    options: unitChoices.map((name, i) => (
      <option key={i} value={name}>
        {name}
      </option>
    )),
  },
  //category: {value: '', inputType: "hidden", class: 'col-sm-8', endRow: true},
  description: {
    value: "",
    inputType: "tinymce",
    rows: "3",
    class: "col mb-1 mt-1",
    label: "",
    placeholder: "Body",
    config: {
      menubar: false,
      statusbar: false,
      content_css: "/static/bootstrap/css/bootstrap.css",
      plugins: "autolink link image lists print preview",
      block_formats: "Paragraph=p;Header 3=h3;Header 4=h4;Header 5=h5;",
      toolbar:
        "bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | sup sub | formatselect | removeformat",
    },
    endRow: true,
  },
  /*required: {value: '', inputType: "checkbox", class: 'col-sm-3 text-right'},
    freq_num: {
        label: "Due Every",
        value: 1, class: 'col-sm-3', inputType: "number", 
    },
    freq_unit: {
        label: "Period",
        value: '', class: 'col-sm-3', inputType: "select",
        options: repFreqChoices.map((name, i) => (<option key={i} value={name}>{name}</option>))
    },
    from_date: {label: 'From Date: ', value:'', inputType:"date", class: 'col', endRow: true},*/
};

export const frequencyFields = {
  freq_num: {
    label: "Due Every",
    value: 1,
    class: "col",
    inputType: "number",
  },
  freq_unit: {
    label: "Period",
    value: "",
    class: "col",
    inputType: "select",
    options: repFreqChoices.map((name, i) => (
      <option key={i} value={name || ""}>
        {name || "Every Report"}
      </option>
    )),
  },
  from_date: { label: "From Date: ", value: "", inputType: "date", class: "col", endRow: true },
};

export const conversionFields = {
  factor: { label: "", value: 0, inputType: "number", class: "col", endRow: true },
};

export const placeholderFields = {
  placeholder: { label: "", value: "", inputType: "text", class: "col", endRow: true },
};

const inputTypeChoices = ["Standard", "Boolean", "Searchable Children", "Select Children", "Selectable Twin Children"];

export const inputTypeFields = {
  input_type: {
    label: "Input type",
    value: "",
    class: "col",
    inputType: "select",
    options: inputTypeChoices.map((name, i) => (
      <option key={i} value={name.toLowerCase().replace(/ /g, "_")}>
        {name}
      </option>
    )),
    endRow: true,
  },
};

export const metricEditFields = {
  freq_num: {
    label: "Due Every",
    value: 1,
    class: "col",
    inputType: "number",
  },
  freq_unit: {
    label: "Period",
    value: "",
    class: "col",
    inputType: "select",
    options: repFreqChoices.map((name, i) => (
      <option key={i} value={name}>
        {name}
      </option>
    )),
  },
  from_date: { label: "From Date: ", value: "", inputType: "date", class: "col", endRow: true },
};

export const documentFields = {
  title: { value: "", class: "col", endRow: true },
  description: {
    value: "",
    inputType: "tinymce",
    rows: "3",
    class: "col mb-1 mt-1",
    label: "",
    placeholder: "Body",
    config: {
      menubar: false,
      statusbar: false,
      content_css: "/static/bootstrap/css/bootstrap.css",
      plugins: "autolink link image lists print preview",
      block_formats: "Paragraph=p;Header 3=h3;Header 4=h4;Header 5=h5;",
      toolbar:
        "bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | sup sub | formatselect | removeformat",
    },
    endRow: true,
  },
};
